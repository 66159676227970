.imprint {
  $this-height: 40rem;
  $this-transition: 360ms;

  @apply overflow-hidden relative;

  max-height: 100%;
  padding-bottom: $this-height;
  transition: all $this-transition;

  &.--collapsed {
    @apply overflow-hidden cursor-pointer;

    max-height: $this-height;
    overflow: hidden;

    &:after {
      @apply opacity-100 flex;
    }
  }

  &.--hover {
    
    &:after {
      content: "Impressum ausklappen";
    }
  }

  &:after {
    @apply hidden absolute opacity-0 w-full flex-col justify-end;

    content: "";
    height: $this-height;
    bottom: 0;
    background: linear-gradient(0deg, #1a202c, rgba(#1a202c, 0));
    transition: all $this-transition;
  }
}