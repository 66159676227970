.about {
  min-height: 150vh;
  transition: transform 660ms cubic-bezier(.86, 0, .32, 1);
  transform: translate3d(0, 0, 0);
  &.--shift {
    transform: translate3d(-200px, 0, 0);
  }
  .container {
    @apply h-full flex-col;
    @screen lg {
      @apply flex-row;
    }
    p {
      @apply text-base font-hairline block;
      font-family: Merriweather, 'Times New Roman', Times, serif;

      @screen md {
        @apply text-2xl;
      }

      @screen lg {
        @apply text-3xl;
      }

      &.small {
        @apply block text-lg;

        @screen md {
          @apply block text-lg;
        }

        @screen lg {
          @apply block text-xl;
        }
      }
    }
    a {
      @apply cursor-pointer text-base font-hairline border-red border-b-4 block h-full;
      font-family: Merriweather, 'Times New Roman', Times, serif;
      transition: all 340ms;
      transition-property: opacity, border, margin;

      @screen md {
        @apply text-2xl;
      }

      @screen lg {
        @apply text-3xl;
      }

      &:hover {
        @apply border-transparent opacity-50 ml-5;
        @screen lg {
          @apply mr-5 ml-0;
        }
      }

      &[data-modal-anchor] {
        @apply inline;
      }
    }
  }
  // img {
  //   @apply h-full;
  // }
}
