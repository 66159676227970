[data-modal] {
  @apply fixed inset-y-0 bg-red text-white overflow-y-auto opacity-100 z-20;

  // transform: translate3D(, 0, 0);
  left: 100vw;
  right: -100vw;
  transition: all 660ms cubic-bezier(.86,0,.32,1);
  // transition-property: left, right;
  width: 100vw;

  @screen lg {
    width: 50vw;
  }
  
  &.--active {
    @apply block opacity-100;

    // transform: translate3D(0, 0, 0);
    left: 0;
    right: 0;

    button {
      @apply fixed z-30;
    }

    @screen lg {
      left: 50vw;
    }
  }

  button {
    @apply top-0 right-0 px-10 outline-none;

    &:hover {
      @apply opacity-50;
    }
  }

  h1 {
    @apply text-xl font-hairline mt-8 w-2/3;

    @screen lg {
      @apply text-2xl w-full;
    }
  }

  h2 {
    @apply text-xl mt-12;
  }

  p {
    @apply leading-relaxed opacity-90;

    @screen lg {
      // @apply w-2/3;
    }
  }

  .container {
    @apply pb-32 relative;
  }

  hr {
    @apply my-10;
  }
}

[data-modal-backdrop] {
  @apply block inset-0 bg-white opacity-0 z-0;

  transition: all 460ms;

  &.--active {
    @apply block fixed opacity-75 z-10;
  }
}