// @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans+Condensed|IBM+Plex+Sans:100,200,400,500,700|Merriweather|Abril+Fatface?display=swap');

html, body {
  margin: 0;
  padding: 0;
  font-family: 'IBM Plex Sans', sans-serif;
}

h1 {
  font-family: Merriweather, 'Times New Roman', Times, serif;
}

h1, h2, h3, h4, h5 {
  @apply mb-4;
}

h2 {
  @apply text-xl;
}

h3 {
  @apply text-lg;
}

p {
  line-height: 125%;
}

a {
}

main {
  @apply opacity-100;

  // transition: opacity 480ms cubic-bezier(.86,0,.32,1);
  // animation: fadeIn 1000ms cubic-bezier(.86,0,.32,1) forwards;

  // @keyframes fadeIn {
  //   0% {
  //     opacity: 0;
  //   }
  //   100% {
  //     opacity: 1;
  //   }
  // }
}

article {

  header {
    @apply static top-0 overflow-hidden h-screen;

    bottom: 100vh;
  }

  section {
    min-height: 100vh;
  
    @apply sticky flex flex-col justify-start py-20 bg-white;
  }

  footer {
    @apply static top-0 bg-gray-900 text-gray-400 pb-20 overflow-x-hidden;

     // $offset: auto;
     // margin-top: -$offset;
     // padding-bottom: $offset; 

    h1 {
      @apply text-xl font-hairline;

      @screen lg {
        @apply text-2xl;
      }
    }

    h2 {
      @apply text-lg;

      @screen lg {
        @apply text-xl;
      }
    }

    h1, h2, h3 {
      @apply text-white;
    }

    a {
      @apply underline;

      transition: all 440ms;

      &:hover {
        @apply no-underline opacity-75;
      }
    }

    p {
      @apply mb-10 leading-relaxed;
    }

    .container {
      @apply mx-auto;
      
      .flex {
        @apply flex-col;

        @screen lg {
          @apply flex-row;
        }
      }
    }

    .opening-hours {
      @apply w-full;

      @screen lg {
        @apply w-1/3;
      }

      &__inner {
        @apply pt-20;

        @screen lg {
          @apply border-gray-700 border-r;
        }
      }
    }

    .imprint {
      @apply w-full mt-8 py-20;

      @screen lg {
        @apply w-2/3 mt-0 pl-20;
      }
    }

  }
}