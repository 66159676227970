.swiper {
  @apply h-full;

  &__wrapper {
    @apply w-full h-full bg-black;
  }

  &__slide {
    @apply absolute inset-0 block opacity-0 w-full h-full;
    
    img {
      @apply absolute top-0 left-0 w-full h-full object-cover object-center;
      max-width: 100%;  
      max-height: 100vh;
    }

    &.--active {
      @apply opacity-100;
    }
  }
}