@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'base';

.teaser {
  @apply flex h-full;

  .container {
    @apply z-10;
  }

  .logo {
    @apply flex justify-between flex-col h-full py-12;

    &__inner {
      @apply w-32;

      @screen md {
        @apply w-56;
      }

      &__main {
        margin: 0 30%;
      }

      &__sub {
        @apply w-full
      }
    }

  }
}

body {

  &.--no-scroll {
    @apply overflow-hidden;
  }

  &.--loading {
    @apply overflow-hidden h-screen;

    main {
      @apply opacity-0;
    }
  }
}

.loading-screen {
  $this-duration: 340ms;
  
  @apply fixed hidden inset-0 bg-white opacity-100;

  transition: opacity $this-duration;

  &.--show {
    @apply block;
  }

  &.--fade-out {
    @apply opacity-0;
  }

  .container {
    @apply flex h-full items-center justify-center;
  }

  .head {
    margin: 0 30%;
    
    filter: grayscale(1) contrast(3);
    transform: scale(10);
    animation: gkAnimation 3000ms cubic-bezier(.86,0,.32,1) forwards;

    @keyframes gkAnimation {
      0% {
        opacity: 0;
        transform: scale(10);
        filter: grayscale(1) contrast(3);
      }

      15% {
        opacity: 0.1;
      }

      20% {
        opacity: 1;
        transform: scale(1);
        filter: grayscale(1) contrast(3);
      }

      70% {
        opacity: 1;
        transform: scale(1);
        filter: grayscale(1) contrast(3);
      }

      100% {
        transform: scale(1);
        filter: grayscale(0) contrast(1);
      }
    }
  }

  .sub {
    opacity: 0;
    animation: subAnimation 240ms ease-out forwards;
    animation-delay: 1800ms;
    transform: scale(10);

    @keyframes subAnimation {
      0% {
        opacity: 0;
        transform: scale(10);
      }
      
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

@import 'about';
@import 'imprint';
@import 'modal';
@import 'swiper';